var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "card Profissional" }, [
    _c("div", { staticClass: "title-card" }, [
      _c("span", [
        _vm._v("Kristal"),
        _c("span", { staticClass: "tipo-produto" }, [_vm._v("Profissional")]),
      ]),
    ]),
    _c("div", { staticClass: "valor" }, [
      _c("span", [_vm._v("R$50,00")]),
      _c("span", { staticClass: "mes" }, [_vm._v("/mês")]),
    ]),
    _c("div", { staticClass: "gratuidade" }, [
      _c("span", [_vm._v("Pagamento à vista")]),
    ]),
    _c("div", { staticClass: "vantagens-plano" }, [
      _c("ul", [
        _c("li", { staticClass: "vantagem-item" }, [
          _c(
            "div",
            { staticClass: "img-check" },
            [
              _c("v-img", {
                staticClass: "check",
                attrs: { src: require("@/assets/icons/check_icon.svg") },
              }),
            ],
            1
          ),
          _c("span", [_vm._v("Dashboard")]),
        ]),
        _c("li", { staticClass: "vantagem-item" }, [
          _c(
            "div",
            { staticClass: "img-check" },
            [
              _c("v-img", {
                staticClass: "check",
                attrs: { src: require("@/assets/icons/check_icon.svg") },
              }),
            ],
            1
          ),
          _c("span", [_vm._v("Tela de rentabilidade")]),
        ]),
        _c("li", { staticClass: "vantagem-item" }, [
          _c(
            "div",
            { staticClass: "img-check" },
            [
              _c("v-img", {
                staticClass: "check",
                attrs: { src: require("@/assets/icons/check_icon.svg") },
              }),
            ],
            1
          ),
          _c("span", [_vm._v("Tela de carteira/movimento")]),
        ]),
        _c("li", { staticClass: "vantagem-item" }, [
          _c(
            "div",
            { staticClass: "img-check" },
            [
              _c("v-img", {
                staticClass: "check",
                attrs: { src: require("@/assets/icons/check_icon.svg") },
              }),
            ],
            1
          ),
          _c("span", [_vm._v("Tela de Liquidez")]),
        ]),
        _c("li", { staticClass: "vantagem-item" }, [
          _c(
            "div",
            { staticClass: "img-check" },
            [
              _c("v-img", {
                staticClass: "check",
                attrs: { src: require("@/assets/icons/check_icon.svg") },
              }),
            ],
            1
          ),
          _c("span", [_vm._v("480 produtos no geral")]),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showBtn,
            expression: "showBtn",
          },
        ],
        staticClass: "btn",
        on: { click: _vm.botaoClicado },
      },
      [_c("span", [_vm._v(_vm._s(_vm.titleBtn))])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }