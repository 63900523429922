var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "card basic" }, [
    _c("div", { staticClass: "header-card" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("PROFISSIONAL")]),
      ]),
    ]),
    _c("div", { staticClass: "descricao-card" }, [
      _c("span", [
        _vm._v(
          " Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint "
        ),
      ]),
    ]),
    _c("div", { staticClass: "body-card" }, [
      _c("div", { staticClass: "items" }, [
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" Navegue na por todas as telas da plataforma "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" Todas as opções de forma ILIMITADA "),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }