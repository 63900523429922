var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "selecao-assinatura" }, [
    _c(
      "div",
      { staticClass: "forma-azul" },
      [
        _c("NavBar"),
        _c("div", { staticClass: "textos-capa" }, [
          _vm._m(0),
          _vm._m(1),
          _c("div", { staticClass: "btns-capa" }, [
            _c(
              "div",
              {
                staticClass: "mensal",
                class: { clicado: _vm.anualOuMensal },
                on: { click: _vm.changeMensalOuAnual },
              },
              [_c("span", [_vm._v("Mensal")])]
            ),
            _c(
              "div",
              {
                staticClass: "anual",
                class: { clicado: !_vm.anualOuMensal },
                on: { click: _vm.changeMensalOuAnual },
              },
              [_c("span", [_vm._v("Anual")])]
            ),
          ]),
        ]),
        _vm.tiopUsuarioEscolhido == 0
          ? _c(
              "div",
              { attrs: { id: "investidor" } },
              [
                _c("div", { staticClass: "container-cards" }, [
                  _c(
                    "div",
                    { ref: "principais", staticClass: "principais" },
                    [
                      _c("CardBasicInvest", {
                        staticClass: "item",
                        on: { buttonClicked: _vm.buttonClicked },
                      }),
                      _c("CardPremiumInvest", {
                        staticClass: "item",
                        on: { buttonClicked: _vm.buttonClicked },
                      }),
                      _c("CardGoldInvest", {
                        staticClass: "item",
                        on: { buttonClicked: _vm.buttonClicked },
                      }),
                      _c("CardExpertInvest", {
                        staticClass: "item",
                        on: { buttonClicked: _vm.buttonClicked },
                      }),
                      _c("CardProfissionalInvest", {
                        staticClass: "item",
                        on: { buttonClicked: _vm.buttonClicked },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "btn-navigation" }, [
                    _c(
                      "div",
                      { staticClass: "prev", on: { click: _vm.goBack } },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/icons/chevron_left_icon.svg"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "next", on: { click: _vm.goNext } },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/icons/chevron_right_icon.svg"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _c("Porque"),
                !_vm.telaMobile ? _c("MelhorPlano") : _vm._e(),
                _vm.telaMobile
                  ? _c("div", { staticClass: "cards-mobile" }, [
                      _vm._m(2),
                      _c(
                        "div",
                        { staticClass: "cards" },
                        [
                          _c("CardMobileBasic", {
                            staticClass: "card",
                            attrs: { data: _vm.dataIniciante },
                          }),
                          _c("CardMobilePremium", {
                            staticClass: "card",
                            attrs: { data: _vm.dataPadrao },
                          }),
                          _c("CardMobileGold", {
                            staticClass: "card",
                            attrs: { data: _vm.dataSenior },
                          }),
                          _c("CardMobileExpert", {
                            staticClass: "card",
                            attrs: { data: _vm.dataExpert },
                          }),
                          _c("CardMobileProfessional", {
                            staticClass: "card",
                            attrs: { data: _vm.dataProfissional },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.tiopUsuarioEscolhido == 1
          ? _c(
              "div",
              { attrs: { id: "acessor" } },
              [
                _c("div", { staticClass: "container-cards" }, [
                  _c(
                    "div",
                    { ref: "principais", staticClass: "principais" },
                    [
                      _c("CardBasic", {
                        staticClass: "item",
                        on: { buttonClicked: _vm.buttonClicked },
                      }),
                      _c("CardPremium", {
                        staticClass: "item",
                        on: { buttonClicked: _vm.buttonClicked },
                      }),
                      _c("CardGold", {
                        staticClass: "item",
                        on: { buttonClicked: _vm.buttonClicked },
                      }),
                      _c("CardExpert", {
                        staticClass: "item",
                        on: { buttonClicked: _vm.buttonClicked },
                      }),
                      _c("CardProfissional", {
                        staticClass: "item",
                        on: { buttonClicked: _vm.buttonClicked },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "btn-navigation" }, [
                    _c(
                      "div",
                      { staticClass: "prev", on: { click: _vm.goBack } },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/icons/chevron_left_icon.svg"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "next", on: { click: _vm.goNext } },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/icons/chevron_right_icon.svg"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _c("Porque"),
                !_vm.telaMobile ? _c("MelhorPlano") : _vm._e(),
                _vm.telaMobile
                  ? _c("div", { staticClass: "cards-mobile" }, [
                      _vm._m(3),
                      _c(
                        "div",
                        { staticClass: "cards" },
                        [
                          _c("CardMobileBasic", {
                            staticClass: "card",
                            attrs: { data: _vm.dataIniciante },
                          }),
                          _c("CardMobilePremium", {
                            staticClass: "card",
                            attrs: { data: _vm.dataPadrao },
                          }),
                          _c("CardMobileGold", {
                            staticClass: "card",
                            attrs: { data: _vm.dataSenior },
                          }),
                          _c("CardMobileExpert", {
                            staticClass: "card",
                            attrs: { data: _vm.dataExpert },
                          }),
                          _c("CardMobileProfessional", {
                            staticClass: "card",
                            attrs: { data: _vm.dataProfissional },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [
        _vm._v(" Escolha do seu plano "),
        _c("span", { staticClass: "negrito" }, [_vm._v("Kristal Connect")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descricao-capa" }, [
      _c("p", [
        _vm._v(
          " Fazendo assinaturas você tem acesso a inúmeras vantagens para aproveitar ao máximo seus investimentos. Podendo escolher planos mensais ou anuais. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descricoes" }, [
      _c("span", { staticClass: "titles" }, [
        _vm._v(" Encontre o melhor plano pra você "),
      ]),
      _c("span", { staticClass: "sub-title" }, [
        _vm._v("Compare as versões e veja qual a melhor para o seu negócio."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descricoes" }, [
      _c("span", { staticClass: "titles" }, [
        _vm._v(" Encontre o melhor plano pra você "),
      ]),
      _c("span", { staticClass: "sub-title" }, [
        _vm._v("Compare as versões e veja qual a melhor para o seu negócio."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }