<template>
    <v-card class="card basic">
        <div class="header-card">
            <div class="title">
                <span>PADRÃO</span>
            </div>
        </div>
          <div class="descricao-card">
            <span>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint
            </span>
        </div>

        <div class="body-card">
           <div class="items">
               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.title}} 
                   </span>

               </div>

               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.title2}} 
                   </span>

               </div>

               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.title3}} 
                   </span>

               </div>

               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.acoes}} Ações 
                   </span>

               </div>

               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.fundoAcoes}} Fundos de Ações 
                   </span>

               </div>
               
               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.titulosPublico}} Títulos Públicos 
                   </span>

               </div>
               
               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.lciLca}} LCI,LCA
                   </span>

               </div>
               
               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.fundosCurtoPrazo}} Fundos de Curto Prazo
                   </span>

               </div>
               
               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.fundosRendaFixa}} Fundos de renda Fixa 
                   </span>

               </div>
               
               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.fundosReferenciados}} Fundos Referenciados
                   </span>

               </div>
               
               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.cdbs}} CDBs
                   </span>

               </div>
               
               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.debentures}} Debêntures
                   </span>

               </div>
               
               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.fundosMultiMercados}} Fundos Multimercados
                   </span>

               </div>
               
               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.fundosImobiliarios}} Fundos Imobiliários 
                   </span>

               </div>
               
               <div class="item">
                   <figure>
                         <img src="@/assets/icons/check_signature_icon.svg" alt="check imagem" />
                   </figure>
                   <span class="descricao">
                       {{data.fundosCambiais}} Fundos Cambiais
                   </span>

               </div>
           </div>

        </div>
        
    </v-card>
</template>
<script>
export default {
    props:{
        data:{type: Object}
    }
}
</script>
<style scoped>
.card {
  width: 330px;
  height: 420px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px !important;
  margin-top: 1.5rem;
  
}
.header-card{
    padding: .5rem 0 .5rem 1rem;
    background: var(--principal-azul);
    color: white;
    
}
.descricao-card{
    padding: .8rem;
    text-align: center;
}
.descricao-card span{
    font-weight: 500;
    font-size: .9em;
}
.title span{
    font-weight: 700;
}
.body-card{
    padding: 0 .5rem;
    overflow-y: auto;
       overflow-y: auto;
}
.items{
      height: 280px;
    overflow-y: auto !important;
}
.items::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.items::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
.items::-webkit-scrollbar-thumb {
  background: rgb(167, 166, 166);
  border-radius: 20px;
}

.item{
    display: flex;
    align-items: center;
    margin: .9rem 0;
}
.item>:is(figure, span){
    margin: 0 .5rem;
}

.item figure{
   
    width: 25px;
    height: 25px;
    overflow: hidden;
}
.item figure img{
    width: 100%;
    height: 100%;
}
.item .descricao{
    font-size: .85em;
}


</style>