var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "porque-template" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("Por que assinar o Kristal Connect?")]),
      ]),
      _c("div", { staticClass: "explicacoes" }, [
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("../../../../assets/icons/dollar_icon.svg"),
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "textos" }, [
            _c("span", { staticClass: "title-texto" }, [_vm._v("Lorem Ipsum")]),
            _c("span", { staticClass: "desc" }, [
              _vm._v(
                "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint"
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("../../../../assets/icons/dashboard_icon.svg"),
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "textos" }, [
            _c("span", { staticClass: "title-texto" }, [_vm._v("Lorem Ipsum")]),
            _c("span", { staticClass: "desc" }, [
              _vm._v(
                "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint"
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("../../../../assets/icons/bars_graph_icon.svg"),
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "textos" }, [
            _c("span", { staticClass: "title-texto" }, [_vm._v("Lorem Ipsum")]),
            _c("span", { staticClass: "desc" }, [
              _vm._v(
                "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint"
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("../../../../assets/icons/wallet_icon.svg"),
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "textos" }, [
            _c("span", { staticClass: "title-texto" }, [_vm._v("Lorem Ipsum")]),
            _c("span", { staticClass: "desc" }, [
              _vm._v(
                "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }