var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "melhor-plano" }, [
    _vm._m(0),
    _c("div", { staticClass: "tabela" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "accordions" },
        [
          _c("Accordion", {
            attrs: { dados: _vm.dadosPrincipais, title: "Principais" },
          }),
          _c("Accordion", {
            attrs: { dados: _vm.dadosOutrosProdutos, title: "Mais produtos" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descricoes" }, [
      _c("span", { staticClass: "titles" }, [
        _vm._v(" Encontre o melhor plano pra você "),
      ]),
      _c("span", { staticClass: "sub-title" }, [
        _vm._v("Compare as versões e veja qual a melhor para o seu negócio."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cards" }, [
      _c("div", { staticClass: "card empty" }),
      _c("div", { staticClass: "card iniciante" }, [
        _c("div", { staticClass: "title-card" }, [
          _c("span", [
            _vm._v("Kristal "),
            _c("span", { staticClass: "tipo-produto" }, [_vm._v("Iniciante")]),
          ]),
        ]),
        _c("div", { staticClass: "descricao" }, [
          _c("p", [
            _vm._v(
              " Conta investimento livre. Um pouco da experiência em suas mãos. "
            ),
          ]),
        ]),
        _c("div", { staticClass: "valor" }, [_c("span", [_vm._v("Gratuito")])]),
      ]),
      _c("div", { staticClass: "card padrao" }, [
        _c("div", { staticClass: "title-card" }, [
          _c("span", [
            _vm._v("Kristal "),
            _c("span", { staticClass: "tipo-produto" }, [_vm._v("Padrão")]),
          ]),
        ]),
        _c("div", { staticClass: "descricao" }, [
          _c("p", [
            _vm._v(
              "Conta com investimento R$10,00. Mais ferramentas e vantagens."
            ),
          ]),
        ]),
        _c("div", { staticClass: "valor" }, [
          _c("span", [_vm._v("R$10,00")]),
          _c("span", { staticClass: "mes" }, [_vm._v("/mês")]),
        ]),
      ]),
      _c("div", { staticClass: "card senior" }, [
        _c("div", { staticClass: "title-card" }, [
          _c("span", [
            _vm._v("Kristal "),
            _c("span", { staticClass: "tipo-produto" }, [_vm._v("Sênior")]),
          ]),
        ]),
        _c("div", { staticClass: "descricao" }, [
          _c("p", [
            _vm._v(
              " Conta com investimento inicial de R$15,00. Mais quantidade na carteira. "
            ),
          ]),
        ]),
        _c("div", { staticClass: "valor" }, [
          _c("span", [_vm._v("R$15,00")]),
          _c("span", { staticClass: "mes" }, [_vm._v("/mês")]),
        ]),
      ]),
      _c("div", { staticClass: "card expert" }, [
        _c("div", { staticClass: "title-card" }, [
          _c("span", [
            _vm._v("Kristal "),
            _c("span", { staticClass: "tipo-produto" }, [_vm._v("Expert")]),
          ]),
        ]),
        _c("div", { staticClass: "descricao" }, [
          _c("p", [
            _vm._v(
              " Conta com investimento inicial de R$30,00. Mais quantidade na carteira. "
            ),
          ]),
        ]),
        _c("div", { staticClass: "valor" }, [
          _c("span", [_vm._v("R$30,00")]),
          _c("span", { staticClass: "mes" }, [_vm._v("/mês")]),
        ]),
      ]),
      _c("div", { staticClass: "card profissional" }, [
        _c("div", { staticClass: "title-card" }, [
          _c("span", [
            _vm._v("Kristal "),
            _c("span", { staticClass: "tipo-produto" }, [
              _vm._v("Profissional"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "descricao" }, [
          _c("p", [
            _vm._v("Conta com investimento R$50,00. Quantidades ilimitadas."),
          ]),
        ]),
        _c("div", { staticClass: "valor" }, [
          _c("span", [_vm._v("R$50,00")]),
          _c("span", { staticClass: "mes" }, [_vm._v("/mês")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }