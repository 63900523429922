var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "card basic" }, [
    _c("div", { staticClass: "header-card" }, [
      _c("div", { staticClass: "title" }, [_c("span", [_vm._v("SÊNIOR")])]),
    ]),
    _c("div", { staticClass: "descricao-card" }, [
      _c("span", [
        _vm._v(
          " Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint "
        ),
      ]),
    ]),
    _c("div", { staticClass: "body-card" }, [
      _c("div", { staticClass: "items" }, [
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" " + _vm._s(_vm.data.title) + " "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" " + _vm._s(_vm.data.title2) + " "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" " + _vm._s(_vm.data.title3) + " "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" " + _vm._s(_vm.data.title4) + " "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" " + _vm._s(_vm.data.acoes) + " Ações "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" " + _vm._s(_vm.data.fundoAcoes) + " Fundos de Ações "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(
              " " + _vm._s(_vm.data.titulosPublico) + " Títulos Públicos "
            ),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" " + _vm._s(_vm.data.lciLca) + " LCI,LCA "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(
              " " +
                _vm._s(_vm.data.fundosCurtoPrazo) +
                " Fundos de Curto Prazo "
            ),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(
              " " + _vm._s(_vm.data.fundosRendaFixa) + " Fundos de Renda Fixa "
            ),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(
              " " +
                _vm._s(_vm.data.fundosReferenciados) +
                " Fundos Referenciados "
            ),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" " + _vm._s(_vm.data.cdbs) + " CDBs "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" " + _vm._s(_vm.data.debentures) + " Debêntures "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(
              " " +
                _vm._s(_vm.data.fundosMultiMercados) +
                " Fundos Multimercados "
            ),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(
              " " +
                _vm._s(_vm.data.fundosImobiliarios) +
                " Fundos Imobiliários "
            ),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check_signature_icon.svg"),
                alt: "check imagem",
              },
            }),
          ]),
          _c("span", { staticClass: "descricao" }, [
            _vm._v(" " + _vm._s(_vm.data.fundosCambiais) + " Fundos Cambiais "),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }