<template>
  <div class="melhor-plano">
    <div class="descricoes">
      <span class="titles"> Encontre o melhor plano pra você </span>
      <span class="sub-title"
        >Compare as versões e veja qual a melhor para o seu negócio.</span
      >
    </div>

    <div class="tabela">
      <div class="cards">
        <div class="card empty"></div>

        <div class="card iniciante">
          <div class="title-card">
            <span>Kristal <span class="tipo-produto">Iniciante</span></span>
          </div>

          <div class="descricao">
            <p>
              Conta investimento livre. Um pouco da experiência em suas mãos.
            </p>
          </div>

          <div class="valor">
            <span>Gratuito</span>
          </div>
        </div>

        <div class="card padrao">
          <div class="title-card">
            <span>Kristal <span class="tipo-produto">Padrão</span></span>
          </div>

          <div class="descricao">
            <p>Conta com investimento R$10,00. Mais ferramentas e vantagens.</p>
          </div>

          <div class="valor">
            <span>R$10,00</span>
            <span class="mes">/mês</span>
          </div>
        </div>

        <div class="card senior">
          <div class="title-card">
            <span>Kristal <span class="tipo-produto">Sênior</span></span>
          </div>
          <div class="descricao">
            <p>
              Conta com investimento inicial de R$15,00. Mais quantidade na
              carteira.
            </p>
          </div>
          <div class="valor">
            <span>R$15,00</span>
            <span class="mes">/mês</span>
          </div>
        </div>

        <div class="card expert">
          <div class="title-card">
            <span>Kristal <span class="tipo-produto">Expert</span></span>
          </div>
          <div class="descricao">
            <p>
              Conta com investimento inicial de R$30,00. Mais quantidade na
              carteira.
            </p>
          </div>
          <div class="valor">
            <span>R$30,00</span>
            <span class="mes">/mês</span>
          </div>
        </div>

        <div class="card profissional">
          <div class="title-card">
            <span>Kristal <span class="tipo-produto">Profissional</span></span>
          </div>
          <div class="descricao">
            <p>Conta com investimento R$50,00. Quantidades ilimitadas.</p>
          </div>
          <div class="valor">
            <span>R$50,00</span>
            <span class="mes">/mês</span>
          </div>
        </div>
      </div>

      <div class="accordions">
        <Accordion :dados="dadosPrincipais" title="Principais" />
        <Accordion :dados="dadosOutrosProdutos" title="Mais produtos" />
      </div>
    </div>
  </div>
</template>

<script>
import * as DataFromAccordion from "./logic/index";
import Accordion from "./Accordion.vue";
export default {
  components: {
    Accordion,
  },
  data() {
    return {
      dadosPrincipais: undefined,
      dadosOutrosProdutos: undefined,
    };
  },
  created() {
    this.dadosPrincipais = DataFromAccordion.melhorPlanoPrincipais;
    this.dadosOutrosProdutos = DataFromAccordion.melhorPlanoMaisProdutos;
  },
};
</script>

<style scoped>
.melhor-plano {
  width: 100%;
  padding: 3rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descricoes span {
  display: block;
  text-align: center;
}
.descricoes .titles {
  font-weight: 600 !important;
  font-size: 1.5em !important;
  line-height: 52px !important;
  letter-spacing: 0.15px !important;
  color: #494949 !important;
}
.tabela {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cards {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cards .card {
  height: auto;
  width: 100%;
  padding: 0.5rem;
  height: 220px;
  display: flex;
  flex-direction: column;
}
.cards :not(:nth-child(1)).card {
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}
.card .title-card {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #494949;
  margin: 0.2rem 0;
}
.card .valor {
  font-weight: bold;
  font-size: 20px;
  line-height: 37px;
  letter-spacing: 0.15px;
  color: #494949;
}
.title-card span {
  font-size: 0.92em;
  transition: 0.2s ease-in-out;
}
.empty {
  flex: 2;
}
.iniciante {
  flex: 1;
  border-top: 30px solid #e4e4e4;
}
.tipo-produto {
  font-weight: 800;
}
.iniciante .tipo-produto {
  color: #e4e4e4;
}
.padrao {
  flex: 1;
  border-top: 30px solid var(--principal-azul);
}
.padrao .tipo-produto {
  color: var(--principal-azul);
}
.senior {
  flex: 1;
  border-top: 30px solid #f3a03c;
}
.senior .tipo-produto {
  color: #f3a03c;
}

.valor .mes {
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.15px;
  margin-left: 5px;
}
.expert {
  flex: 1;
  border-top: 30px solid #f3a03c;
}
.expert .tipo-produto {
  color: #f3a03c;
}
.profissional {
  flex: 1;
  border-top: 30px solid #b6e552;
}
.profissional .tipo-produto {
  color: #b6e552;
}

/* FIM DOS CARDS DA TABELA */

/* INICIO ACCORDIONS */
.accordions {
  width: 100%;
}

@media screen and (min-width: 1020px) {
  .card {
    height: 270px !important;
    position: relative;
  }
  .card .valor {
    position: absolute;
    bottom: 5px;
    width: 100%;
    left: 0;
    text-align: center;
  }
}
@media screen and (max-width: 1150px) {
  .card .descricao p {
    font-size: 0.85em !important;
  }
}
@media screen and (max-width: 1020px) {
  .card {
    height: 250px !important;
    position: relative;
  }
  .card .valor {
    position: absolute;
    bottom: 5px;
    width: 100%;
    left: 0;
    text-align: center;
  }
  .title-card span {
    font-size: 0.72em;
  }
}

@media screen and (max-width: 820px) {
  .card {
    height: 270px !important;
  }
}
</style>