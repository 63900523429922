<template>
  <div class="porque-template">
    <div class="title">
      <span>Por que assinar o Kristal Connect?</span>
    </div>
    <div class="explicacoes">
      <div class="item">
        <figure>
          <img src="../../../../assets/icons/dollar_icon.svg" alt="" />
        </figure>

        <div class="textos">
          <span class="title-texto">Lorem Ipsum</span>
          <span class="desc"
            >Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint</span
          >
        </div>
      </div>
      <div class="item">
        <figure>
          <img src="../../../../assets/icons/dashboard_icon.svg" alt="" />
        </figure>
        <div class="textos">
          <span class="title-texto">Lorem Ipsum</span>
          <span class="desc"
            >Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint</span
          >
        </div>
      </div>
      <div class="item">
        <figure>
          <img src="../../../../assets/icons/bars_graph_icon.svg" alt="" />
        </figure>
        <div class="textos">
          <span class="title-texto">Lorem Ipsum</span>
          <span class="desc"
            >Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint</span
          >
        </div>
      </div>
      <div class="item">
        <figure>
          <img src="../../../../assets/icons/wallet_icon.svg" alt="" />
        </figure>
        <div class="textos">
          <span class="title-texto">Lorem Ipsum</span>
          <span class="desc"
            >Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.porque-template {
  background: #f6f6f6;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem .5rem;
}
.porque-template .title{
    margin-bottom: 2rem;
}
.porque-template .title span{
    font-weight: 600;
font-size: 1.3em;
line-height: 52px;
letter-spacing: 0.15px;
color: #494949;
}
.explicacoes{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: black;
}
.explicacoes>div{
    flex: 1 1 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.item{
    margin: .5rem 0;
}
.item figure{
    background: white;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .3em 0;
}
.item figure img{
    width: 24px;
    filter: invert(55%) sepia(64%) saturate(546%) hue-rotate(182deg) brightness(92%) contrast(100%);
}
.item .textos{
    max-width: 65%;

}
.item .textos span{
    display: block;
}
.item .textos .title-texto{
    font-weight: bold;
    margin: .4rem 0;
}
.item .textos .desc{
    font-weight: 400;
    font-size: .9em;
    line-height: 27px;
text-align: center;
letter-spacing: 0.15px;
}
</style>