<template>
      <v-card class="card Profissional">
          <div class="title-card">
            <span>Kristal<span class="tipo-produto">Profissional</span></span>
          </div>
          <div class="valor">
            <span>R$50,00</span>
            <span class="mes">/mês</span>
          </div>
          <div class="gratuidade">
            <span>Pagamento à vista</span>
          </div>

          <div class="vantagens-plano">
            <ul>
              <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check" src="@/assets/icons/check_icon.svg" />
                </div>
                <span>Dashboard</span
                >
              </li>
              <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check" src="@/assets/icons/check_icon.svg" />
                </div>
                <span>Tela de rentabilidade</span>
              </li>
              <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check" src="@/assets/icons/check_icon.svg" />
                </div>
                <span>Tela de carteira/movimento</span>
              </li>
               <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check" src="@/assets/icons/check_icon.svg" />
                </div>
                <span>Tela de Liquidez</span>
              </li>
               <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check" src="@/assets/icons/check_icon.svg" />
                </div>
                <span>480 produtos no geral</span>
              </li>
               <li class="vantagem-item">
                <div class="img-check">
                  <v-img class="check" src="@/assets/icons/check_icon.svg" />
                </div>
                <span>Até 50 carteiras</span>
              </li>
            </ul>
          </div>
          <div class="btn" v-show="showBtn" @click="botaoClicado">
            <span>{{titleBtn}}</span>
          </div>
        </v-card>
</template>
<script>
export default {
  props:{
      titleBtn: {type: String, default: 'Criar conta KristalProfissional'},
       showBtn: {type: Boolean, default: true}
  },
 data(){
   return{
     profissional: {
       title:'KristalProfissional',
       valor: 'R$50,00',
       value: 50,
       id: 4

     }
   }
 },
 methods:{
   botaoClicado(){
     this.$emit('buttonClicked', this.profissional)
   }
 }
}
</script>
<style scoped>
.card {
  width: 300px;
  height: 455px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-top: 1.5rem;
  padding: 2rem 1.5rem;
  position: relative;
}
.card .title-card {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #494949;
}
.card .gratuidade span {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: rgba(73, 73, 73, 0.5);
}
.card .btn {
  border: 1px solid #a4a4a4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  border-radius: 20px;
  color: #a4a4a4;
  margin: 3.5rem 0;
  cursor: pointer;
    position: absolute;
  bottom: -35px;
  width: 90%;
  left: 17px;
}
.card .valor {
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  letter-spacing: 0.15px;
  color: #494949;
}
.valor .mes {
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.15px;
  margin-left: 5px;
}
.vantagens-plano {
  margin: 1.5rem 0;
}
.vantagens-plano ul {
  padding: 0 !important;
}
.vantagens-plano ul li {
  list-style: none;
  list-style: none;
  padding: 0.5rem 0;
  display: flex;
}
.vantagens-plano ul li span {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #494949;
}
.vantagem-item .img-check {
  width: 15px;
  height: 15px;
  margin-right: 0.7rem;
}
 .check {
  filter: invert(15%) sepia(66%) saturate(2157%) hue-rotate(34deg) brightness(126%) contrast(107%) !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tipo-produto {
  font-weight: 800;
  color: #e4e4e4;
}
.basic .btn:hover {
  color: white;
  background: #a4a4a4;
  transition: 0.2s ease-in-out;
}
.Profissional .tipo-produto {
  color: #B6E552;
}
.Profissional .vantagens-plano ul li .check {
  filter: invert(26%) sepia(27%) saturate(4818%) hue-rotate(331deg)
    brightness(99%) contrast(92%);
}
.Profissional .btn {
  border: 1px solid #B6E552;
  color: #B6E552;
  transition: 0.2s ease-in-out;
}
.Profissional .btn:hover {
  background: #B6E552;
  color: white;
}
.Profissional .btn:hover span{
  display: none;
}
.Profissional .btn:hover:before{
  content: 'Escolher KristalProfissional';
}
</style>