<template>
  <div class="accordion" v-if="dados.length">
    <div class="header-accordion" @click="openOrClose">
      <div class="title">
        <span>{{title}}</span>
      </div>
    </div>

    <div class="body-accordion" v-if="opened">
      <div class="line" v-for="item in dados" :key="item.id">
        <div class="line-content desc">
          <span> {{item.title}} </span>
        </div>
        <div class="line-content check">
          <img src="@/assets/icons/check_signature_icon.svg" v-if="item.check1" />
          <span v-if="item.val1" class="item-value">{{item.val1}}</span>
        </div>
        <div class="line-content check">
          <img src="@/assets/icons/check_signature_icon.svg" alt="" v-if="item.check2" />
          <span v-if="item.val2" class="item-value">{{item.val2}}</span>
        </div>
        <div class="line-content check">
          <img src="@/assets/icons/check_signature_icon.svg" alt="" v-if="item.check3"/>
           <span v-if="item.val3" class="item-value">{{item.val3}}</span>
        </div>
         <div class="line-content check">
          <img src="@/assets/icons/check_signature_icon.svg" alt="" v-if="item.check4"/>
           <span v-if="item.val4" class="item-value">{{item.val4}}</span>
        </div>
         <div class="line-content check">
          <img src="@/assets/icons/check_signature_icon.svg" alt="" v-if="item.check5"/>
           <span v-if="item.val5" class="item-value">{{item.val5}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    openOrClose() {
      this.opened == false ? (this.opened = true) : (this.opened = false);
    },
  },
  props:{
    dados: {type: Array},
    title:{type: String, required: true}
  }
};
</script>

<style  scoped>
.accordion {
  width: 100%;
}
.header-accordion {
  height: 40px;
  background: #f6f6f6;
  cursor: pointer;
}
.header-accordion .title {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  color: var(--principal-azul);
  position: relative;
}
.header-accordion .title span {
  font-size: 0.9em;
}
.header-accordion .title img {
  position: absolute;
  left: 10px;
  filter: invert(53%) sepia(49%) saturate(1529%) hue-rotate(191deg)
    brightness(99%) contrast(91%);
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.body-accordion {
}
.body-accordion .line {
  display: flex;
}
.line > div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}
.line > div .item-value{
  font-weight: 700;
  color: var(--principal-azul);
}
.line .desc {
  flex: 2;
}
.line .check {
  flex: 1;
}
</style>