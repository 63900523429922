import { render, staticRenderFns } from "./CardPremium.vue?vue&type=template&id=e2a13886&scoped=true"
import script from "./CardPremium.vue?vue&type=script&lang=js"
export * from "./CardPremium.vue?vue&type=script&lang=js"
import style0 from "./CardPremium.vue?vue&type=style&index=0&id=e2a13886&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2a13886",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\gh\\Topazio\\Site\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e2a13886')) {
      api.createRecord('e2a13886', component.options)
    } else {
      api.reload('e2a13886', component.options)
    }
    module.hot.accept("./CardPremium.vue?vue&type=template&id=e2a13886&scoped=true", function () {
      api.rerender('e2a13886', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/SelecaoAssinatura/components/cards/investidor/CardPremium.vue"
export default component.exports