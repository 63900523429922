export const melhorPlanoPrincipais = [
    {
        title: 'Dashboard, navegar pela plataforma',
        check1: '@/assets/icons/chevron_down_icon.svg',
        check2: '@/assets/icons/chevron_down_icon.svg',
        check3: '@/assets/icons/chevron_down_icon.svg',
        check4: '@/assets/icons/chevron_down_icon.svg',
        check5: '@/assets/icons/chevron_down_icon.svg',
        id: 1
    },
    {
        title: 'Tela de rentabilidade',
        check1: '',
        check2: '@/assets/icons/chevron_down_icon.svg',
        check3: '@/assets/icons/chevron_down_icon.svg',
        check4: '@/assets/icons/chevron_down_icon.svg',
        check5: '@/assets/icons/chevron_down_icon.svg',
        id: 2
    },
    {
        title: 'Tela de carteira/movimento',
        check1: '',
        check2: '@/assets/icons/chevron_down_icon.svg',
        check3: '@/assets/icons/chevron_down_icon.svg',
        check4: '@/assets/icons/chevron_down_icon.svg',
        check5: '@/assets/icons/chevron_down_icon.svg',
        id: 3
    },
    {
        title: 'Tela Liquidez',
        check1: '',
        check2: '',
        check3: '@/assets/icons/chevron_down_icon.svg',
        check4: '@/assets/icons/chevron_down_icon.svg',
        check5: '@/assets/icons/chevron_down_icon.svg',
        id: 6
    },
    {
        title: 'Ações',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: 2,
        val2: 5,
        val3:10,
        val4: 40,
        val5: 'Ilimitado',
        id: 4
    },
    {
        title: 'Fundos de Ações',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: 2,
        val2: 5,
        val3:10,
        val4: 40,
        val5: 'Ilimitado',
        id: 5
    },

]

export const melhorPlanoMaisProdutos =[
    {
        title: 'Títulos Públicos',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: 5,
        val2: 10,
        val3:20,
        val4: 40,
        val5: 'Ilimitado',
        id: 10
    },
    {
        title: 'LCA,LCI',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: 5,
        val2: 10,
        val3:20,
        val4: 40,
        val5: 'Ilimitado',
        id: 20
    },
    {
        title: 'Fundos de Curto Prazo',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: 5,
        val2: 10,
        val3:20,
        val4: 40,
        val5: 'Ilimitado',
        id: 30
    },
    {
        title: 'Fundos de Renda Fixa',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: 5,
        val2: 10,
        val3:20,
        val4: 40,
        val5: 'Ilimitado',
        id: 40
    },
    {
        title: 'Fundos Referenciados',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: '0',
        val2: 5,
        val3:20,
        val4: 40,
        val5: 'Ilimitado',
        id: 50
    },
    {
        title: 'CDBs',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: 5,
        val2: 5,
        val3:20,
        val4: 40,
        val5: 'Ilimitado',
        id: 60
    },
    {
        title: 'Debêntures',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: 1,
        val2: 5,
        val3:10,
        val4: 40,
        val5: 'Ilimitado',
        id: 70
    },
    {
        title: 'Fundos Multimercados',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: 5,
        val2: 5,
        val3:10,
        val4: 40,
        val5: 'Ilimitado',
        id: 80
    },
    {
        title: 'Fundos Imobiliários',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: 1,
        val2: 5,
        val3:10,
        val4: 40,
        val5: 'Ilimitado',
        id: 90
    },
    {
        title: 'Fundos Cambiais',
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
        val1: '0',
        val2: 5,
        val3:10,
        val4: 40,
        val5: 'Ilimitado',
        id: 100
    },
]

export const mobileInformacoes = [
    {
        id: 1,
        slug: 'iniciante',
        title: 'Dashboard, navegar pela plataforma',
        acoes: 2,
        fundoAcoes:2,
        titulosPublico: 5,
        lciLca: 5,
        fundosCurtoPrazo:5,
        fundosRendaFixa: 5,
        cdbs: 5,
        debentures: 1,
        fundosMultiMercados:5,
        fundosImobiliarios:1
    },
    {
        id: 2,
        slug: 'padrao',
        title: 'Dashboard, navegar pela plataforma',
        title2: 'Tela de rentabilidade',
        title3: 'Tela de carteira/movimento',
        acoes: 5,
        fundoAcoes:5,
        titulosPublico: 10,
        lciLca: 10,
        fundosCurtoPrazo:10,
        fundosRendaFixa: 10,
        fundosReferenciados: 5,
        cdbs: 5,
        debentures: 5,
        fundosMultiMercados:5,
        fundosImobiliarios:5,
        fundosCambiais: 5,
    },
    {
        id: 3,
        slug: 'senior',
        title: 'Dashboard, navegar pela plataforma',
        title2: 'Tela de rentabilidade',
        title3: 'Tela de carteira/movimento',
        title4: 'Tela Liquidez',
        acoes: 10,
        fundoAcoes:10,
        titulosPublico: 20,
        lciLca: 20,
        fundosCurtoPrazo:20,
        fundosRendaFixa: 20,
        fundosReferenciados: 20,
        cdbs: 20,
        debentures: 10,
        fundosMultiMercados:10,
        fundosImobiliarios:10,
        fundosCambiais: 10,
    },
    {
        id: 4,
        slug: 'expert',
        title: 'Dashboard, navegar pela plataforma',
        title2: 'Tela de rentabilidade',
        title3: 'Tela de carteira/movimento',
        title4: 'Tela Liquidez',
        acoes: 40,
        fundoAcoes:40,
        titulosPublico: 40,
        lciLca: 40,
        fundosCurtoPrazo:40,
        fundosRendaFixa: 40,
        fundosReferenciados: 40,
        cdbs: 40,
        debentures: 40,
        fundosMultiMercados:40,
        fundosImobiliarios:40,
        fundosCambiais: 40,
    },
    {
        id: 5,
        slug: 'profissional',
        title: 'Dashboard, navegar pela plataforma',
        title2: 'Tela de rentabilidade',
        title3: 'Tela de carteira/movimento',
        title4: 'Tela Liquidez',
        acoes: 'Ilimitado',
        fundoAcoes:'Ilimitado',
        titulosPublico: 'Ilimitado',
        lciLca: 'Ilimitado',
        fundosCurtoPrazo:'Ilimitado',
        fundosRendaFixa: 'Ilimitado',
        fundosReferenciados: 'Ilimitado',
        cdbs: 'Ilimitado',
        debentures: 'Ilimitado',
        fundosMultiMercados:'Ilimitado',
        fundosImobiliarios:'Ilimitado',
        fundosCambiais: 'Ilimitado',
    },
]