var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", [
    _c("div", { staticClass: "itens" }, [
      _vm._m(0),
      _c("div", { staticClass: "item entrar", on: { click: _vm.goLogin } }, [
        _c("span", [_vm._v("Entrar")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item planos" }, [
      _c("span", [_vm._v("Planos")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }