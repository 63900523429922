<template>
  <div class="selecao-assinatura">
    <div class="forma-azul">
      <NavBar />

      <div class="textos-capa">
        <div class="title">
          <span>
            Escolha do seu plano
            <span class="negrito">Kristal Connect</span></span
          >
        </div>
        <div class="descricao-capa">
          <p>
            Fazendo assinaturas você tem acesso a inúmeras vantagens para
            aproveitar ao máximo seus investimentos. Podendo escolher planos
            mensais ou anuais.
          </p>
        </div>
        <div class="btns-capa">
          <div
            class="mensal"
            @click="changeMensalOuAnual"
            :class="{ clicado: anualOuMensal }"
          >
            <span>Mensal</span>
          </div>
          <div
            class="anual"
            @click="changeMensalOuAnual"
            :class="{ clicado: !anualOuMensal }"
          >
            <span>Anual</span>
          </div>
        </div>
      </div>

      <div id="investidor" v-if="tiopUsuarioEscolhido == 0">
        <div class="container-cards">
          <div class="principais" ref="principais">
            <CardBasicInvest class="item" @buttonClicked="buttonClicked" />
            <CardPremiumInvest class="item" @buttonClicked="buttonClicked" />
            <CardGoldInvest class="item" @buttonClicked="buttonClicked" />
            <CardExpertInvest class="item" @buttonClicked="buttonClicked" />
            <CardProfissionalInvest
              class="item"
              @buttonClicked="buttonClicked"
            />
          </div>

          <div class="btn-navigation">
            <div class="prev" @click="goBack">
              <img src="@/assets/icons/chevron_left_icon.svg" alt="" />
            </div>
            <div class="next" @click="goNext">
              <img src="@/assets/icons/chevron_right_icon.svg" alt="" />
            </div>
          </div>
        </div>

        <Porque />
        <MelhorPlano v-if="!telaMobile" />

        <div class="cards-mobile" v-if="telaMobile">
          <div class="descricoes">
            <span class="titles"> Encontre o melhor plano pra você </span>
            <span class="sub-title"
              >Compare as versões e veja qual a melhor para o seu negócio.</span
            >
          </div>

          <div class="cards">
            <CardMobileBasic :data="dataIniciante" class="card" />
            <CardMobilePremium :data="dataPadrao" class="card" />
            <CardMobileGold :data="dataSenior" class="card" />
            <CardMobileExpert :data="dataExpert" class="card" />
            <CardMobileProfessional :data="dataProfissional" class="card" />
          </div>
        </div>
      </div>

      <div id="acessor" v-if="tiopUsuarioEscolhido == 1">
        <div class="container-cards">
          <div class="principais" ref="principais">
            <CardBasic class="item" @buttonClicked="buttonClicked" />
            <CardPremium class="item" @buttonClicked="buttonClicked" />
            <CardGold class="item" @buttonClicked="buttonClicked" />
            <CardExpert class="item" @buttonClicked="buttonClicked" />
            <CardProfissional class="item" @buttonClicked="buttonClicked" />
          </div>

          <div class="btn-navigation">
            <div class="prev" @click="goBack">
              <img src="@/assets/icons/chevron_left_icon.svg" alt="" />
            </div>
            <div class="next" @click="goNext">
              <img src="@/assets/icons/chevron_right_icon.svg" alt="" />
            </div>
          </div>
        </div>

        <Porque />
        <MelhorPlano v-if="!telaMobile" />

        <div class="cards-mobile" v-if="telaMobile">
          <div class="descricoes">
            <span class="titles"> Encontre o melhor plano pra você </span>
            <span class="sub-title"
              >Compare as versões e veja qual a melhor para o seu negócio.</span
            >
          </div>

          <div class="cards">
            <CardMobileBasic :data="dataIniciante" class="card" />
            <CardMobilePremium :data="dataPadrao" class="card" />
            <CardMobileGold :data="dataSenior" class="card" />
            <CardMobileExpert :data="dataExpert" class="card" />
            <CardMobileProfessional :data="dataProfissional" class="card" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as DataFromMobileCard from "./components/logic/index";

import NavBar from "./components/NavBar.vue";
import Porque from "./components/Porque.vue";
import MelhorPlano from "./components/MelhorPlano.vue";
import CardBasic from "./components/cards/acessor/CardBasic.vue";
import CardPremium from "./components/cards/acessor/CardPremium.vue";
import CardGold from "./components/cards/acessor/CardGold.vue";
import CardExpert from "./components/cards/acessor/CardExpert.vue";
import CardProfissional from "./components/cards/acessor/CardProfissional.vue";

import CardBasicInvest from "./components/cards/investidor/CardBasic.vue";
import CardPremiumInvest from "./components/cards/investidor/CardPremium.vue";
import CardGoldInvest from "./components/cards/investidor/CardGold.vue";
import CardExpertInvest from "./components/cards/investidor/CardExpert.vue";
import CardProfissionalInvest from "./components/cards/investidor/CardProfissional.vue";

import CardMobileBasic from "./components/mobile/CardMobileBasic.vue";
import CardMobilePremium from "./components/mobile/CardMobilePremium.vue";
import CardMobileGold from "./components/mobile/CardMobileGold.vue";
import CardMobileExpert from "./components/mobile/CardMobileExpert.vue";
import CardMobileProfessional from "./components/mobile/CardMobileProfissional.vue";

export default {
  components: {
    NavBar,
    Porque,
    MelhorPlano,
    CardBasic,
    CardPremium,
    CardGold,
    CardMobileBasic,
    CardMobilePremium,
    CardMobileGold,
    CardExpert,
    CardProfissional,
    CardMobileExpert,
    CardMobileProfessional,
    CardBasicInvest,
    CardPremiumInvest,
    CardGoldInvest,
    CardExpertInvest,
    CardProfissionalInvest,
  },
  data() {
    return {
      anualOuMensal: true,
      windowWidth: window.innerWidth,
      dadosCardMobile: undefined,
      dataIniciante: undefined,
      dataPadrao: undefined,
      dataSenior: undefined,
      dataExpert: undefined,
      dataProfissional: undefined,
    };
  },
  created() {
    const [iniciante, padrao, senior, expert, profissional] =
      DataFromMobileCard.mobileInformacoes.map((item) => {
        return { ...item };
      });

    this.dataIniciante = iniciante;
    this.dataPadrao = padrao;
    this.dataSenior = senior;
    this.dataExpert = expert;
    this.dataProfissional = profissional;
  },
  methods: {
    goNext() {
      this.$refs.principais.scrollLeft += 200;
    },
    goBack() {
      this.$refs.principais.scrollLeft -= 200;
    },
    changeMensalOuAnual() {
      this.anualOuMensal == true
        ? (this.anualOuMensal = false)
        : (this.anualOuMensal = true);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    buttonClicked(planoEscolhido) {
      if (planoEscolhido.id != 0) {
        let periodicidadePagamento = this.anualOuMensal ? "Mensal" : "Anual";
        let annualPlan = this.anualOuMensal ? 0 : 1;
        this.$store.dispatch("PUT_CURRENT_PLAN", {
          ...planoEscolhido,
          periodicidadePagamento: periodicidadePagamento,
        });
        this.$router.push({
          name: "checkout",
          params: { plan: planoEscolhido.id, annualPlan: annualPlan },
        });
      } else {
        let preLoginUser = sessionStorage.getItem('user')
        let userAtualizado = JSON.parse(preLoginUser)
        sessionStorage.removeItem('user')

        userAtualizado.planType = planoEscolhido.id
        sessionStorage.removeItem('preUserRegister')
        this.$router.push({name: 'visaoGeral'})

      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    telaMobile() {
      return this.windowWidth > 800 ? false : true;
    },
    tiopUsuarioEscolhido() {
      return this.$store.getters.$profileType.id

    },
  },
};
</script>
<style  scoped>
/* CARDS */
.container-cards {
  position: relative;
  max-width: 1250px;
  margin: 0 auto;
}
.principais {
  display: flex;
  gap: 0.5rem;
  padding: 1.5rem;
  max-width: 1250px;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
}
.container-cards .btn-navigation {
  position: absolute;
  left: 0;
  right: 0;
  top: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-navigation > div {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--principal-azul);
  cursor: pointer;
}
.btn-navigation > div img {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(124deg)
    brightness(106%) contrast(101%);
}
.principais .item {
  min-width: 300px;
  max-width: 300px;
  height: 455px;
}
.cards .card {
  display: inline-block;
}
.cards,
.cards-mobile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.5rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}
/* FIM DOS CARDS */
/* .selecao-assinatura {
} */
.nav {
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.forma-azul {
  height: 654px;
  background: var(--principal-azul);
  position: relative;
}
.textos-capa {
  margin-top: 1.5rem;
  text-align: center;
  color: white;
  max-width: 750px;
  margin: 0 auto;
}
.textos-capa .title span {
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  /* identical to box height */

  letter-spacing: 0.15px;
}
.textos-capa .title .negrito {
  font-weight: 600;
}
.textos-capa .descricao-capa {
  margin-top: 1.5rem;
}
.textos-capa .descricao-capa p {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.15px;
}
.btns-capa {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btns-capa > div {
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  margin: 1rem 0.5rem;
  border: 1px solid white;
}
.clicado {
  background: white;
  color: var(--principal-azul);
}
.descricoes span {
  display: block;
  text-align: center;
}
.descricoes .titles {
  font-weight: 600 !important;
  font-size: 1.5em !important;
  line-height: 52px !important;
  letter-spacing: 0.15px !important;
  color: #494949 !important;
}

@media screen and (max-width: 700px) {
  .container-cards .btn-navigation {
    top: 300px;
  }
}
</style>