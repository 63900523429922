var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dados.length
    ? _c("div", { staticClass: "accordion" }, [
        _c(
          "div",
          { staticClass: "header-accordion", on: { click: _vm.openOrClose } },
          [
            _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v(_vm._s(_vm.title))]),
            ]),
          ]
        ),
        _vm.opened
          ? _c(
              "div",
              { staticClass: "body-accordion" },
              _vm._l(_vm.dados, function (item) {
                return _c("div", { key: item.id, staticClass: "line" }, [
                  _c("div", { staticClass: "line-content desc" }, [
                    _c("span", [_vm._v(" " + _vm._s(item.title) + " ")]),
                  ]),
                  _c("div", { staticClass: "line-content check" }, [
                    item.check1
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/icons/check_signature_icon.svg"),
                          },
                        })
                      : _vm._e(),
                    item.val1
                      ? _c("span", { staticClass: "item-value" }, [
                          _vm._v(_vm._s(item.val1)),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "line-content check" }, [
                    item.check2
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/icons/check_signature_icon.svg"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    item.val2
                      ? _c("span", { staticClass: "item-value" }, [
                          _vm._v(_vm._s(item.val2)),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "line-content check" }, [
                    item.check3
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/icons/check_signature_icon.svg"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    item.val3
                      ? _c("span", { staticClass: "item-value" }, [
                          _vm._v(_vm._s(item.val3)),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "line-content check" }, [
                    item.check4
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/icons/check_signature_icon.svg"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    item.val4
                      ? _c("span", { staticClass: "item-value" }, [
                          _vm._v(_vm._s(item.val4)),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "line-content check" }, [
                    item.check5
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/icons/check_signature_icon.svg"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    item.val5
                      ? _c("span", { staticClass: "item-value" }, [
                          _vm._v(_vm._s(item.val5)),
                        ])
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }